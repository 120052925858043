import { DASHBOARD_DATA_ENDPOINT, MAP_DATA_ENDPOINT, SNAPSHOT_ENDPOINT, TRAILER_IDS_ENDPOINT } from "../configs/endpoints";
import { CoolingStatusEntries, CoolingStatusEntry, TrailerAlert, TrailerId, SnapshotData, Edr_Chrgr_Mrg_State } from "../types/trailerPageTypes";
import { TableContentItem, TruData } from "../types/types";
import Requester from "./requester";

// TODO: fix this
// const publicRequester = new Requester(
//     import.meta.env.API_HOSTNAME,
//     "public",
//     import.meta.env.API_VERSION,
//     import.meta.env.API_PORT,
//     true
// );
export const publicRequester = new Requester(
    "http://localhost",
    "api/public",
    "v0",
    "8000",
    true
);

const firstObj: TableContentItem = {
    "id": 0,
    "severity": "Critical",
    "Edr_Batt_48v_SoC_pc": 80.0,
    "Edr_Trlr_isOpen": false,
    "trailerName": "TRL_001",
    "Edr_Cmpmt_Main_Cooling_isActive": false,
    "Edr_Cmpmt_Main_Setpoint_T_C": -22.0,
    "Edr_Cmpmt_Main_T_C": -20.0,
    "Edr_Cmpmt_Mid_Cooling_isActive": true,
    "Edr_Cmpmt_Mid_Setpoint_T_C": -5.0,
    "Edr_Cmpmt_Mid_T_C": -4.0,
    "Edr_Cmpmt_Rear_Cooling_isActive": false,
    "Edr_Edgedev_Wwan_Signal_dBm": 20,
    "timeSinceDataLastReceived": "",
}

const secondObj: TableContentItem = {
    "id": 1,
    "severity": "Urgent",
    "Edr_Batt_48v_SoC_pc": 60.0,
    "Edr_Trlr_isOpen": false,
    "trailerName": "TRL_002",
    "Edr_Cmpmt_Main_Cooling_isActive": true,
    "Edr_Cmpmt_Main_Setpoint_T_C": -12.0,
    "Edr_Cmpmt_Main_T_C": -10.0,
    "Edr_Cmpmt_Mid_Cooling_isActive": false,
    "Edr_Cmpmt_Rear_Cooling_isActive": false,
    "Edr_Edgedev_Wwan_Signal_dBm": -10,
    "timeSinceDataLastReceived": "",
}
const thirdObj: TableContentItem = {
    "id": 2,
    "severity": "Maintenance",
    "Edr_Batt_48v_SoC_pc": 70.0,
    "Edr_Trlr_isOpen": false,
    "trailerName": "TRL_003",
    "Edr_Cmpmt_Main_Cooling_isActive": true,
    "Edr_Cmpmt_Main_Setpoint_T_C": -22.0,
    "Edr_Cmpmt_Main_T_C": -20.0,
    "Edr_Cmpmt_Mid_Cooling_isActive": true,
    "Edr_Cmpmt_Mid_Setpoint_T_C": -5.0,
    "Edr_Cmpmt_Mid_T_C": -4.0,
    "Edr_Cmpmt_Rear_Cooling_isActive": false,
    "Edr_Edgedev_Wwan_Signal_dBm": -15,
    "timeSinceDataLastReceived": "",
}
const fourthObj: TableContentItem = {
    "id": 3,
    "severity": "ASAP",
    "Edr_Batt_48v_SoC_pc": 20.0,
    "Edr_Trlr_isOpen": true,
    "trailerName": "TRL_004",
    "Edr_Cmpmt_Main_Cooling_isActive": true,
    "Edr_Cmpmt_Main_Setpoint_T_C": -2.0,
    "Edr_Cmpmt_Main_T_C": -2.0,
    "Edr_Cmpmt_Mid_Cooling_isActive": false,
    "Edr_Cmpmt_Mid_Setpoint_T_C": -15.0,
    "Edr_Cmpmt_Mid_T_C": -14.0,
    "Edr_Cmpmt_Rear_Cooling_isActive": true,
    "Edr_Cmpmt_Rear_Setpoint_T_C": -15.0,
    "Edr_Cmpmt_Rear_T_C": -14.0,
    "Edr_Edgedev_Wwan_Signal_dBm": -15,
    "timeSinceDataLastReceived": "",
}
const dashboardData = [firstObj, secondObj, thirdObj, fourthObj]
const tru_data_1: TruData = {
    "point" : {
        "Edr_Tru_Latitude_Deg": 51.6954,
        "Edr_Tru_Longitude_Deg": 0.3211,
    },
    "name": "TRU_001"
}

const tru_data_2: TruData = {
    "point" : {
        "Edr_Tru_Latitude_Deg": 51.2964,
        "Edr_Tru_Longitude_Deg": 0.3311,
    },
    "name": "TRU_002"
}

const tru_data_3: TruData = {
    "point" : {
        "Edr_Tru_Latitude_Deg": 52.2974,
        "Edr_Tru_Longitude_Deg": 0.3411,
    },
    "name": "TRU_003"
}

const tru_data_4: TruData = {
    "point" : {
        "Edr_Tru_Latitude_Deg": 51.8974,
        "Edr_Tru_Longitude_Deg": 0.3411,
    },
    "name": "TRU_004"
}

const truData:TruData[] = [tru_data_1, tru_data_2, tru_data_3, tru_data_4]

const alert2: TrailerAlert = {
    "code": 7,
    "severity": 1,
    "description": "This is an example description for a WARNING event"
}
const alert1: TrailerAlert = {
    "code": 34,
    "severity": 0,
    "description": "This is an example description for an OK event"
}
const alert3: TrailerAlert = {
    "code": 12,
    "severity": 2,
    "description": "This is an example description for a CRITICAL event"
}
const alerts1: TrailerAlert[] = [alert3, alert2, alert2, alert1, alert1]
const alerts2: TrailerAlert[] = [alert2]

const mainCompartment: CoolingStatusEntry = {
    "name": "MAIN",
    "temperatureControlOn": true,
    "setpoint": -22,
    "actual": -19
}
const middleCompartment: CoolingStatusEntry = {
    "name": "MIDDLE",
    "temperatureControlOn": true,
    "setpoint": -12,
    "actual": -19
}
const rearCompartment: CoolingStatusEntry = {
    "name": "REAR",
    "temperatureControlOn": false,
    "setpoint": 6,
    "actual": -22
}
const cooling_summary1: CoolingStatusEntries = [mainCompartment, middleCompartment, rearCompartment]
const cooling_summary2: CoolingStatusEntries = [mainCompartment, middleCompartment, rearCompartment]


export function getDashboardTableData() {
    return dashboardData;
}

export function getTruMapData() {
    return truData
}

export function getTruIds() {
    return ["TRU_001", "TRU_002"] as TrailerId[];
}

const Edr_Chrgr_Mrg_State_val: Edr_Chrgr_Mrg_State = "ON AC";

export function getSnapshotData(trailerId: TrailerId) {
    if(trailerId === "TRU_001") {
        const ret: SnapshotData = {
            "alerts": alerts1,
            "coolingStatusEntries": cooling_summary1,
            "energyStatus": {"solarGain": "1.6kW", "systemConsumption": "0.98kWh/km"},
            "batteryStatus": {"Edr_Batt_48v_SoC_pc": 77, "timeLastCharged": "11/21/2024 10:06:36AM"},
            "timeSinceDataLastReceived": "11/21/2024 10:06:36AM",
            "Edr_Env_Ambient_T_C": 10,
            "Edr_Trlr_isOpen": false,
            "Edr_Chrgr_Mrg_State_enum": Edr_Chrgr_Mrg_State_val,
        };
        return ret;
    }
    else {
        return {
            "alerts": alerts2,
            "coolingStatusEntries": cooling_summary2,
            "energyStatus": {"solarGain": "3.2kW", "systemConsumption": "1.6kWh/km"},
            "batteryStatus": {"Edr_Batt_48v_SoC_pc": 31, "timeLastCharged": "11/21/2024 8:06:36AM"},
            "timeSinceDataLastReceived": "11/21/2024 6:06:36AM",
            "Edr_Env_Ambient_T_C": 27,
            "Edr_Trlr_isOpen": true,
            "Edr_Chrgr_Mrg_State_enum": Edr_Chrgr_Mrg_State_val,
        }
    }
}


// export async function getDashboardTableData() {
//     return await publicRequester.get(DASHBOARD_DATA_ENDPOINT);
// }

// export async function getTruMapData() {
//     return await publicRequester.get(MAP_DATA_ENDPOINT);
// }

// export async function getTruIds() {
//     return await publicRequester.get(TRAILER_IDS_ENDPOINT);
// }

// export async function getSnapshotData(trailerId: TrailerId) {
//     return await publicRequester.get(`${SNAPSHOT_ENDPOINT}?trailerId=${trailerId}`);
// }
