import { SeverityLegend } from "../types/types";

// TODO: change to appropriate colours
export const severityLegend: SeverityLegend = [
    {
        name: "Critical",
        colour: "red",
    },
    {
        name: "Urgent",
        colour: "#f44700",
    },
    {
        name: "ASAP",
        colour: "green",
    },
    {
        name: "Maintenance",
        colour: "teal",
    },
];
