import {
  Map as ReactMap,
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { ReactElement, useEffect, useState } from "react";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import bbox from "@turf/bbox";
import { Feature, featureCollection, point } from "@turf/helpers";
import mapboxgl from "mapbox-gl";
import "./MapComponent.css";
import { mapBoxAccessToken } from "../../configs/mapbox";
import { getTruMapData } from "../../services/api";
import { TruData } from "../../types/types";
import { Typography } from "@mui/material";

export default function MapComponent({height, showTitle}: {height: number, showTitle?: boolean}) {
  const mapRef = React.useRef<MapRef | null>(null);
  const [loading, setLoading] = useState(false);
  const [fetchedOnce, setFetchedOnce] = useState(false);
  const [pinList, setPinList] = useState<ReactElement[]>();

  const pointList: Array<Feature> = [];

    // TODO: this would likely be better as a periodic request to better track the TRUs
    useEffect(() => {
      const getDashboardTableDataRequest = async() => {
          const data = await getTruMapData();
          if(data instanceof Array && data?.length > 0) {
            parseTruData(data as TruData[])
          }
      };

      if(!fetchedOnce) {
        getDashboardTableDataRequest();
      }
    }, []);

    function parseTruData(data: TruData[]) {
      const truMarkers: ReactElement[] = [];
      setLoading(true);

      data.map((tru, index) => {
        pointList.push(point([tru.point.Edr_Tru_Longitude_Deg, tru.point.Edr_Tru_Latitude_Deg]));
        truMarkers.push(
          <Marker
            key={`marker-${index}`}
            longitude={tru.point.Edr_Tru_Longitude_Deg}
            latitude={tru.point.Edr_Tru_Latitude_Deg}
            anchor="bottom"
            popup={new mapboxgl.Popup()
              .setHTML("<h1 style='color:black'>" + tru.name + "</h1>")
              .setOffset([0, -30])}
            onClick={(e) => {
              // If we let the click event propagates to the map, it will immediately close the popup
              // with `closeOnClick: true`
              e.originalEvent.stopPropagation();
              e.target.togglePopup();
              // setPopupInfo(city);
            }}
          >
            <LocalShippingIcon color="primary" />
          </Marker>
        );
      });
      setPinList(truMarkers);
      setLoading(false);
      const feature = featureCollection(pointList);

      if (feature) {
        // calculate the bounding box of the feature
        const [minLng, minLat, maxLng, maxLat] = bbox(feature);
        if (mapRef && mapRef.current !== null) {
          mapRef.current.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 1000 }
          );
        }
      }
    setFetchedOnce(true);
  };

  return (
    <div>
      {showTitle && 
        <Typography
            variant="h4"
            textAlign="center"
            fontSize={"1.5rem"}
        >
          LOCATIONS
        </Typography>}
      <ReactMap
        ref={mapRef}
        mapboxAccessToken={mapBoxAccessToken}
        initialViewState={{
          longitude: -0.3368390762938842,
          latitude: 51.29690443541291,
          zoom: 14,
        }}
        style={{ width: "100%", height }}
        mapStyle="mapbox://styles/mapbox/light-v10"
        // TODO: forcibly hide this element "mapboxgl-ctrl-bottom-right"
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        {!loading && pinList}
      </ReactMap>
      </div>
  );
};
