import { Dispatch, SetStateAction } from "react";

export type TrailerId = string;
export type TrailerAlert = {
    code: number,
    severity: number,
    description: string,
}

export type CoolingStatusEntry = {
    name: string,                  // Mid
    temperatureControlOn: boolean, // Edr_Cmpmt_Mid_Cooling_isActive
    setpoint: number | null,       // Edr_Cmpmt_Mid_Setpoint_T_C
    actual: number | null,         // Edr_Cmpmt_Mid_T_C
}

export type CoolingStatusEntries = CoolingStatusEntry[];

export type BatteryStatus = {
    Edr_Batt_48v_SoC_pc: number,
    timeLastCharged: string,
}

export type EnergyStatus = {
    solarGain: string,
    systemConsumption: string,
}

// TODO: validate with this
export const TrailerSeverity = {
    ok: 0 as number,
    warn: 1 as number,
    critical: 2 as number
} as const;

export const historyOptionsValues = {
    oneDay: 0 as number,
    halfDay: 1 as number,
    oneHour: 2 as number,
} as const;

export const historyOptions = [
    {
        value: historyOptionsValues.oneDay,
        name: "24 Hours"
    },
    {
        value: historyOptionsValues.halfDay,
        name: "12 Hours"
    },
    {
        value: historyOptionsValues.oneHour,
        name: "1 Hours"
    }
] as const;

export type TrailerIdProps = {
    trailerIds: TrailerId[],
    activeTrailerId: TrailerId,
    setActiveTrailerId: Dispatch<SetStateAction<string>>,
}

export type TrailerAlerts = TrailerAlert[];

// TODO: find all states
export type Edr_Chrgr_Mrg_State = "ON AC";

type SnapshotData_v1 = {
    alerts: TrailerAlerts,
    coolingStatusEntries: CoolingStatusEntries,
    batteryStatus: BatteryStatus,
    energyStatus: EnergyStatus,
    timeSinceDataLastReceived: string,
    Edr_Env_Ambient_T_C: number,
    Edr_Trlr_isOpen: boolean,
    Edr_Chrgr_Mrg_State_enum: Edr_Chrgr_Mrg_State,
}

export type SnapshotData = SnapshotData_v1;
