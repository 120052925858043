import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import MapComponent from "../../components/MapComponent";
import { SnapshotData, TrailerIdProps } from "../../../types/trailerPageTypes";
import { OrangeDivider } from "../../components/Dividers";
import { CoolingDemand } from "./CoolingDemand";
import TrailerAlerts from "./TrailerAlerts";
import "./TrailersPage.css"

function SnapshotLeftPanel({ trailerIdProps, snapshotData }: { trailerIdProps: TrailerIdProps, snapshotData: SnapshotData }) {
    const {trailerIds, activeTrailerId, setActiveTrailerId} = trailerIdProps;
    return (
        <Box
            component="div"
            sx={{"margin":"0.5rem"}}
        >
            <Box component="div" display="grid">
                <InputLabel htmlFor="trailer-ids">Trailer ID</InputLabel>
                <Select
                    name="trailer-ids"
                    id="trailer-ids"
                    value={activeTrailerId}
                    onChange={(e) => {
                        setActiveTrailerId(e.target.value ?? "N/A");
                    }}
                >
                    {trailerIds.map((trailerId) => <MenuItem 
                        value={trailerId}
                        key={trailerId}

                    >
                        {trailerId}
                    </MenuItem>)}
                </Select>
            </Box>
            <CoolingDemand snapshotData={snapshotData} />
        </Box>
    );
}

function SnapshotRightPanel({ snapshotData } : { snapshotData: SnapshotData }) {
    return (
        <Box
            component="div"
            sx={{"margin":"0.5rem"}}
        >
            <Box component="h4">ALERTS</Box>
            <TrailerAlerts snapshotData={snapshotData} />
            <MapComponent height={300} />
        </Box>
    );
}

function SnapshotDisplay({ trailerIdProps, snapshotData }: { trailerIdProps: TrailerIdProps, snapshotData: SnapshotData }) {
    return (
        <Box
            className="snapshot-display"
            component="div"
        >
            <SnapshotLeftPanel
                trailerIdProps={trailerIdProps}
                snapshotData={snapshotData}
            />
            <SnapshotRightPanel snapshotData={snapshotData} />
        </Box>
    );
}

export default function SnapshotSection({ trailerIdProps, snapshotData }: { trailerIdProps: TrailerIdProps, snapshotData: SnapshotData }) {
    return (
        <Box
            className="snapshot-wrapper"
            component="div"
        >
            <OrangeDivider />
            <Box component="h3">SNAPSHOT</Box>
            <SnapshotDisplay trailerIdProps={trailerIdProps} snapshotData={snapshotData} />
        </Box>
    )
}
