import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { contextOptions } from "./configs/frontegg";
import { FronteggProvider } from "@frontegg/react";
import { BrowserRouter } from "react-router-dom";
import "./index.css"

const authOptions = {
  keepSessionAlive: true,
}

const AUTH_PROVIDER_EXISTS = false;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {AUTH_PROVIDER_EXISTS ? 
      <FronteggProvider
        contextOptions={contextOptions}
        hostedLoginBox={true}
        authOptions={authOptions}
      >
          <App />
      </FronteggProvider> : 
      <BrowserRouter>
        <App />
      </BrowserRouter>
    }
  </React.StrictMode>
);