import { Box } from "@mui/material";
import { SnapshotData } from "../../../types/trailerPageTypes";
import { GreyDivider, OrangeDivider } from "../../components/Dividers";
import ReportBar from "./ReportBar";
import { generateHistoryReport } from "./trailerPageHelpers";
import "./TrailersPage.css"

function HistoryGraphs() {
    return <Box sx={{backgroundColor: "white", padding: "1rem"}}>
        TODO: ADD GRAPHS HERE
    </Box>
}

export default function HistorySection({ snapshotData } : { snapshotData: SnapshotData }) {
    return (
        <Box
            className="history-wrapper"
            component="div"
        >
            <OrangeDivider />
            <Box component="h3">HISTORY</Box>
            <Box
                className="history-display"
                component="div"
            >
                <ReportBar
                    generateReportFn={generateHistoryReport}
                    timeSinceDataLastReceived={snapshotData.timeSinceDataLastReceived}
                />
                <GreyDivider />
                <HistoryGraphs />
            </Box>
        </Box>
    )
}
