import { List, MenuItem } from "@mui/material";
import { NavLink } from "react-router-dom"
import logoSunswap from "../../img/logo/Sunswap_Logo_In_Orange.svg";
import { menuItems } from "../../../route/route";
import "./SideMenu.css"

function SunswapLogo() {
    return (
        <NavLink className="side-menu-logo" to="/" >
            <img 
                className="side-menu-sunswap-logo"
                src={logoSunswap}
                height={25}
            >
            </img>
        </NavLink>
    )
}

export function SideMenu() {
    return (
        <List className="side-menu">
            {
                menuItems.map((item, idx) => {
                    return (
                        <NavLink key={idx} className="side-menu-item" to={item.route}>
                            <MenuItem>
                                {item.name}
                            </MenuItem>
                        </NavLink>
                    )
                })
            }
        </List>
    )
}

export default function SideBar() {
    return (
        <div className="side-bar">
            <SunswapLogo />
            <SideMenu />
        </div>

    )
}
