import { Box } from "@mui/material";
import { useState } from "react";
import { useRequester } from "../../../hooks/useRequester";
import SnapshotSection from "./SnapshotSection";
import EnergySection from "./EnergySection";
import HistorySection from "./HistorySection";
import { SnapshotData, TrailerId, TrailerIdProps } from "../../../types/trailerPageTypes";
import { SNAPSHOT_ENDPOINT, TRAILER_IDS_ENDPOINT } from "../../../configs/endpoints";
import { getSnapshotData, getTruIds, publicRequester } from "../../../services/api";
import "./TrailersPage.css"

function TrailersInner({ trailerIdProps } : { trailerIdProps: TrailerIdProps }) {
    // const [data, snapshotDataError] = useRequester(publicRequester,
    //                                                `${SNAPSHOT_ENDPOINT}?trailerId=${trailerIdProps.activeTrailerId}`,
    //                                                10);
    // const requestHadError = snapshotDataError !== null || data === null || data === undefined;
    // const snapshotData = data as SnapshotData;

    const requestHadError = false;
    const snapshotDataError = false;
    const snapshotData = getSnapshotData(trailerIdProps.activeTrailerId);

    return requestHadError ?
        <Box component="div">
            An error has occured: {snapshotDataError}
        </Box> :
        <>
            <SnapshotSection
                trailerIdProps={trailerIdProps}
                snapshotData={snapshotData}
            />
            <HistorySection snapshotData={snapshotData} />
            <EnergySection snapshotData={snapshotData} />
        </>;
}

export default function TrailersPage() {
    // TODO: we should probably just do this once or until success
    // const [trailerIdsData, error] = useRequester(publicRequester,
    //                                              TRAILER_IDS_ENDPOINT,
    //                                              10);
    // const [activeTrailerId, setActiveTrailerId] = useState<TrailerId>("N/A");
    const [activeTrailerId, setActiveTrailerId] = useState<TrailerId>("TRU_001");
    const trailerIds = getTruIds();
    // // Handle initial empty case
    // if(trailerIdsData !== null && 
    //    trailerIdsData !== undefined && 
    //    trailerIdsData instanceof Array && 
    //    trailerIdsData.length > 0 && 
    //    activeTrailerId === "N/A") {
    //     setActiveTrailerId(trailerIdsData[0]);
    // }
    // const trailerIds = trailerIdsData as TrailerId[];
    const error = null;

    return <Box component="div">
        {error !== null || trailerIds === null || trailerIds === undefined ?
            <Box
            component="div"
            textAlign={"center"}
        >
                An error has occured when fetching trailer IDs
            </Box> :
            <TrailersInner trailerIdProps={{trailerIds, activeTrailerId, setActiveTrailerId}} />
        }
    </Box>
}
