import { Box } from "@mui/material";
import { CoolingStatusEntries, CoolingStatusEntry, SnapshotData } from "../../../types/trailerPageTypes";
import { GreyDivider } from "../../components/Dividers";
import "./TrailersPage.css"

export function CoolingStatus({ entry } : { entry: CoolingStatusEntry }) {
    return (
        <Box
            component="div"
            className="cooling-status"
        >
            <Box component="h5">
                {entry.name}
            </Box>
            <Box component="p">
                Temperature Control
            </Box>
            <Box component="h4">
                {entry.temperatureControlOn ? "ON" : "OFF"}
            </Box>
            <Box component="p">
                Setpoint
            </Box>
            <Box component="h4">
                {entry.setpoint !== null ? `${entry.setpoint}°C` : "N/A"}
            </Box>
            <Box component="p">
                Compartment
            </Box>
            <Box component="h4">
                {entry.actual !== null ? `${entry.actual}°C` : "N/A"}
            </Box>
        </Box>
    )
}

function CoolingSummary({ entries } : { entries: CoolingStatusEntries }) {
    return (
        <Box
            component="div"
            className="cooling-summary"
        >
            <CoolingStatus entry={entries[0]} />
            <CoolingStatus entry={entries[1]} />
            <CoolingStatus entry={entries[2]} />
        </Box>
    )
}

export function CoolingDemand({ snapshotData } : { snapshotData: SnapshotData }) {
    return (
        <Box component="div">
            <Box component="h4">COOLING DEMAND</Box>
            <CoolingSummary entries={snapshotData.coolingStatusEntries} />
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">Outside</Box>
                <Box component="span">{snapshotData.Edr_Env_Ambient_T_C}°C</Box>
            </Box>
            <GreyDivider />
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">Charging Status</Box>
                <Box component="span">{snapshotData.Edr_Chrgr_Mrg_State_enum}</Box>
            </Box>
            <GreyDivider />
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">Door</Box>
                <Box component="span">{snapshotData.Edr_Trlr_isOpen ? "OPEN" : "CLOSED"}</Box>
            </Box>
            <GreyDivider />
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">Battery</Box>
                <Box component="span">{snapshotData.batteryStatus.Edr_Batt_48v_SoC_pc}%</Box>
            </Box>
            <GreyDivider />
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">Last Charge</Box>
                <Box component="span">{snapshotData.batteryStatus.timeLastCharged}</Box>
            </Box>
        </Box>
    );
}
