import { Box, Button, MenuItem, Select } from "@mui/material";
import { useState, JSX } from "react";
import { historyOptions, historyOptionsValues } from "../../../types/trailerPageTypes";
import "./TrailersPage.css"

// TODO: Do we want to fetch these from the API? Available periods?
export function getHistoryPeriods() {
    // TODO: use correct type
    let options: JSX.Element[] = [];
    historyOptions.map((option) => options.push(<MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>));
    return options;
}

export default function ReportBar({ generateReportFn, timeSinceDataLastReceived } : 
    { generateReportFn: (_: number) => void, timeSinceDataLastReceived: string }
) {
    const [selectValue, setSelectValue] = useState(historyOptionsValues.oneDay);
    return (
        <Box
            className="generate-report-bar"
            component="div"
        >
            <Select
                variant="filled"
                name="time-period"
                id="time-period"
                onChange={(e) => {
                    const value = typeof e.target.value === "string" 
                        ? parseInt(e.target.value, 10)
                        : e.target.value;
                    setSelectValue(value)
                }}
                value={selectValue}
            >
                {getHistoryPeriods()}
            </Select>
            <Button
                variant="contained"
                onClick={() => generateReportFn(selectValue)}
            >
                Generate Report
            </Button>
            <Box
                className="item-and-value"
                component="div"
            >
                <Box component="span">DATA LAST RECEIVED</Box>
                <Box component="span">{timeSinceDataLastReceived}</Box>
            </Box>
        </Box>
    );
}
