import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SignalHealth, TableContent, TableContentItem } from '../../../types/types';
import Alert_darkBG from '../../img/icons/Dark Background/Alert_darkBG.svg'
import Charge_darkBG from '../../img/icons/Dark Background/Charge_darkBG.svg'
import Doors_darkBG from '../../img/icons/Dark Background/Doors_darkBG.svg'
import FrontComp_darkBG from '../../img/icons/Dark Background/FrontComp_darkBG.svg'
import MidComp_darkBG from '../../img/icons/Dark Background/MidComp_darkBG.svg'
import RearComp_darkBG from '../../img/icons/Dark Background/RearComp_darkBG.svg'
import Reports_darkBG from '../../img/icons/Dark Background/Reports_darkBG.svg'
import Signal_darkBG from '../../img/icons/Dark Background/Signal_darkBG.svg'
import Temperature_darkBG from '../../img/icons/Dark Background/Temperature_darkBG.svg'
import { getDashboardTableData } from '../../../services/api';
import "./Dashboard.css";

// TOOD: fix sorting
type Order = 'asc' | 'desc';

interface HeadCell {
    id: keyof TableContentItem;
    iconSvg: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'severity',
        iconSvg: Alert_darkBG,
    },
    {
        id: 'Edr_Batt_48v_SoC_pc',
        iconSvg: Charge_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Main_Cooling_isActive',
        iconSvg: Temperature_darkBG,
    },
    {
        id: 'Edr_Trlr_isOpen',
        iconSvg: Doors_darkBG,
    },
    {
        id: 'trailerName',
        iconSvg: Reports_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Main_Setpoint_T_C',
        iconSvg: FrontComp_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Main_T_C',
        iconSvg: FrontComp_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Mid_Setpoint_T_C',
        iconSvg: MidComp_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Mid_T_C',
        iconSvg: MidComp_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Rear_Setpoint_T_C',
        iconSvg: RearComp_darkBG,
    },
    {
        id: 'Edr_Cmpmt_Rear_T_C',
        iconSvg: RearComp_darkBG,
    },
    {
        id: 'Edr_Edgedev_Wwan_Signal_dBm',
        iconSvg: Signal_darkBG,
    },
    {
        id: 'timeSinceDataLastReceived',
        iconSvg: Reports_darkBG,
    },
];

interface DashboardTableProps {
    // eslint-disable-next-line
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableContentItem) => void;
    order: Order;
    orderBy: string;
}

function DashboardTableHead(props: DashboardTableProps) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof TableContentItem) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow className="dashboard-table-background">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'center'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <img src={headCell.iconSvg} alt={headCell.id} height="30" />
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function compartmentTempToString(temp: number | undefined) {
    return temp !== undefined ? `${temp}°C` : "-";
}

// TOOD: implement this check
function signalStrengthToQualityString(signalStrength: number) : SignalHealth {
    return signalStrength < -10 ? "Excellent" : "Marginal";
}

export default function DashboardTable() {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof TableContentItem>('id');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [tableData, setTableData] = useState<null | undefined | TableContent>();

    useEffect(() => {
        const getDashboardTableDataRequest = async() => {
            const data = await getDashboardTableData();
            setTableData(data);
        };
        getDashboardTableDataRequest();
    }, []);

    const rows = tableData;

    if(rows === undefined || rows === null) {
        return <></>;
    }

    const handleRequestSort = (
        _: React.MouseEvent<unknown>,
        property: keyof TableContentItem,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{paddingTop: "0.5rem"}}>
            <TableContainer component={Paper}>
                <Table
                    aria-labelledby="dashboard-table"
                    size="small"
                    className="dashboard-table-background"
                >
                    <DashboardTableHead 
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {
                            (rowsPerPage > 0
                                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rows
                            ).map((item, idx) => {
                                const labelId = `table-checkbox-${idx}`;
                                return (
                                    <TableRow 
                                        key={idx}
                                        className={`dashboard-table-severity-${item.severity.toLowerCase()}`}
                                    >
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">{item.Edr_Batt_48v_SoC_pc}%</TableCell>
                                        <TableCell align="center">
                                            {item.Edr_Cmpmt_Main_Cooling_isActive || item.Edr_Cmpmt_Mid_Cooling_isActive || item.Edr_Cmpmt_Rear_Cooling_isActive? "True" : "False"}
                                        </TableCell>
                                        <TableCell align="center">{item.Edr_Trlr_isOpen ? "True" : "False"}</TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            align="center"
                                        >
                                            {item.trailerName}
                                        </TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Main_Setpoint_T_C)}</TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Main_T_C)}</TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Mid_Setpoint_T_C)}</TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Mid_T_C)}</TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Rear_Setpoint_T_C)}</TableCell>
                                        <TableCell align="center">{compartmentTempToString(item.Edr_Cmpmt_Rear_T_C)}</TableCell>
                                        <TableCell align="center">{signalStrengthToQualityString(item.Edr_Edgedev_Wwan_Signal_dBm)}</TableCell>
                                        {/* TODO: handle formatting of time since last received data */}
                                        <TableCell align="center">{item.timeSinceDataLastReceived}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {
                            emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    );
}