import { Box, List, ListItem } from "@mui/material";
import { SnapshotData } from "../../../types/trailerPageTypes";
import { alertCodeToPaddedString, severityToColourString } from "./trailerPageHelpers";
import "./TrailersPage.css"

export default function TrailerAlerts({ snapshotData } : { snapshotData: SnapshotData }) {
    return (
        <List
            sx={{
                overflow: "auto",
                maxHeight: 200,
                minHeight: 200,
                width:"100%",
                marginBottom: "1rem"
            }}
            dense
        >
            {snapshotData.alerts.map((alert, idx) => {
                return (
                    <ListItem 
                        key={idx}
                        className={`alert-base ${severityToColourString(alert.severity)}`}
                    >
                        <Box component="p"><b>{alertCodeToPaddedString(alert.code)}</b></Box>
                        <Box component="p">{alert.description}</Box>
                    </ListItem>
                )
            })}
        </List>
    );
}