import DashboardTable from './DashboardTable';
import MapComponent from '../../components/MapComponent';
import { severityLegend } from '../../../configs/severity';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from "@mui/material/TableCell";
import "./Dashboard.css";

// function StatusBar() {
//     return (
//         <div className="status-bar">
//             <div className="">
//                 {/* STATUS */}
//             </div>
//             <div className="status-bar-items">
//                 {severityLegend.map((item) => {
//                     return (
//                         <div key={item.name} className="status-bar-items-inner">
//                             <div>{item.name}</div>
//                             <div style={{ backgroundColor: item.colour }} />
//                         </div>
//                     )
//                 })}
//             </div>
//         </div>
//     );
// }

function StatusBar() {
    return (
        <TableContainer
            component={Paper}
            className="dashboard-table-background"
            sx={{"paddingBottom": "0rem"}}
        >
            <Table
                sx={{[`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                }}}
                aria-labelledby="status-bar"
                size="small"
                className="dashboard-table-background"
            >

                <TableBody>
                    <TableRow >
                        {severityLegend.map((item) => {
                            return (
                                <>
                                    <TableCell align="center">{item.name}</TableCell>
                                    <TableCell sx={{ backgroundColor: item.colour, maxWidth: "10px" }} />
                                </>
                            )
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// TODO: integrate with report page and remove from here
function ReportsSummary() {
    return (
        <div>
            <Typography
                variant="h4"
                textAlign="center"
                fontSize={"1.5rem"}
            >
                REPORTS
            </Typography>
        </div>
    )
}

// TODO: integrate with performance/data page and remove from here
function PerformanceSummary() {
    return (
        <div>
            <Typography
                variant="h4"
                textAlign="center"
                fontSize={"1.5rem"}
            >
                PERFORMANCE
            </Typography>
        </div>
    )
}

export default function DashboardPage() {
    return (
        <div>
            <StatusBar />
            <DashboardTable />
            <div className="embedded-pages">
                <MapComponent height={400} showTitle />
                <ReportsSummary />
                <PerformanceSummary />
            </div>
        </div>
    );
};
