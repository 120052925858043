import { TrailerSeverity } from "../../../types/trailerPageTypes";

export function severityToColourString(severityNum: number) {
    if(severityNum < TrailerSeverity.ok || severityNum > TrailerSeverity.critical) {
        return "";
    }

    switch(severityNum) {
        case TrailerSeverity.ok:
            return "alert-severity-ok"
        case TrailerSeverity.warn:
            return "alert-severity-warn"
        case TrailerSeverity.critical:
            return "alert-severity-critical"
    }
}

export function alertCodeToPaddedString(alertCode: number) {
    return alertCode.toString().padStart(2, "0");
}

// TODO: give this functionality
export function generateHistoryReport(timePeriod: number) {
    window.alert(`TODO: generate history report for time period ${timePeriod}!`);
    return;
}

// TODO: give this functionality
export function generateEnergyReport(timePeriod: number) {
    window.alert(`TODO: generate energy report for time period ${timePeriod}!`);
    return;
}