// import { useIsAuthenticated, useLoginWithRedirect } from "@frontegg/react";
// import { useEffect } from 'react';
import SideMenu from "./assets/elements/sideMenu/SideMenu";
import TopMenu from "./assets/elements/topMenu/TopMenu";
import { PortalRoutes } from "./route/route";
import "./App.css"

export default function App() {
    // const isAuthenticated = useIsAuthenticated();
    // const loginWithRedirect = useLoginWithRedirect();

    // // Redirect to login automatically
    // useEffect(() => {
    //     if (!isAuthenticated) {
    //         loginWithRedirect();
    //     }
    // }, [isAuthenticated, loginWithRedirect]);

    return (
        <div className="app-display">
            <SideMenu />
            <div className="main-content">
                <TopMenu />
                <PortalRoutes />
            </div>
        </div>
    );
}
