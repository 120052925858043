import SettingsIcon from "@mui/icons-material/Settings";
import Light from "@mui/icons-material/LightMode";
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import { AdminPortal } from "@frontegg/react";
import IconButton from '@mui/material/IconButton';
import "./TopMenu.css"

// TODO: replace icons with SVGs provided (assets/img/icons/...)
export default function TopMenu() {
    return (
        <div className="top-menu">
            <IconButton className="top-menu-icons">
                <HeadsetMicIcon />
            </IconButton>
            <IconButton className="top-menu-icons">
                <SettingsIcon />
            </IconButton>
            <IconButton 
                className="top-menu-icons"
                // onClick={() => AdminPortal.show()}
            >
                <AccountCircleIcon />
            </IconButton>
            <IconButton className="top-menu-icons">
                <Light />
            </IconButton>
            <IconButton className="top-menu-icons">
                <HelpOutlineIcon />
            </IconButton>
        </div>
    );
}
