import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "../assets/pages/dashboardPage/DashboardPage";
import MapPage from "../assets/pages/MapPage";
import { JSX } from 'react';
import TrailersPage from "../assets/pages/trailersPage/TrailersPage";

type MenuItem = {
    name: string,
    route: string,
    pageElement: () => JSX.Element,
}

// TODO: update routes to reflect expected menu item. Change from MapPage
export const menuItems: MenuItem[] = [
    {
        name: "Dashboard",
        route: "/",
        pageElement: () => <DashboardPage />,
    },
    {
        name: "Trailers",
        route: "/trailers",
        pageElement: () => <TrailersPage />,
    },
    {
        name: "Map",
        route: "/map",
        pageElement: () => <MapPage />,
    },
    // {
    //     name: "Service Alerts",
    //     route: "/service_alerts",
    //     pageElement: () => <ServiceAlertsPage />,
    // },
    // {
    //     name: "Reports",
    //     route: "/reports",
    //     pageElement: () => <SettingsPage />,
    // },
    // {
    //     name: "Data",
    //     route: "/data",
    //     pageElement: () => <InformationPage />,
    // },
];

export function PortalRoutes() {
    return (
        <Routes>
            {
                menuItems.map((item) => (
                    <Route
                        key={item.name}
                        path={item.route}
                        element={item.pageElement()}
                    />
                ))
            }

            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
    );
}
