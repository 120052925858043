import { Box } from "@mui/material";
import { SnapshotData } from "../../../types/trailerPageTypes";
import { GreyDivider, OrangeDivider } from "../../components/Dividers";
import ReportBar from "./ReportBar";
import { generateEnergyReport } from "./trailerPageHelpers";
import "./TrailersPage.css"

export default function EnergySection({ snapshotData } : { snapshotData: SnapshotData}) {
    return (
        <Box
            className="energy-wrapper"
            component="div"
        >
            <OrangeDivider />
            <Box component="h3">ENERGY</Box>
            <Box
                className="energy-display"
                component="div"
            >
                <ReportBar
                    generateReportFn={generateEnergyReport}
                    timeSinceDataLastReceived={snapshotData.timeSinceDataLastReceived}
                />
                <GreyDivider />
                <Box
                    className="item-and-value"
                    component="div"
                >
                    <Box component="span">SOLAR ENERGY GAIN</Box>
                    <Box component="span">{snapshotData.energyStatus.solarGain}</Box>
                </Box>
                <GreyDivider />
                <Box
                    className="item-and-value"
                    component="div"
                >
                    <Box component="span">SYSTEM ENERGY CONSUMPTION</Box>
                    <Box component="span">{snapshotData.energyStatus.systemConsumption}</Box>
                </Box>
            </Box>
        </Box>
    )
}
